var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-visit-sidebar","centered":"","visible":_vm.isAddNewVisitSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":""},on:{"ok":function($event){return _vm.$refs.submitButtonRef.click()},"hidden":function($event){_vm.formValidation(_vm.resetblankvisit).resetForm},"change":function (val) { return _vm.changed(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("newVisit"))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"formValidation(\n    resetblankvisit\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('visitDate'),"label-for":"visitDate"}},[_c('b-form-datepicker',{attrs:{"id":"visitDate","locale":_vm.$i18n.locale,"autofocus":"","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.visitdate),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "visitdate", $$v)},expression:"blankVisitData.visitdate"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('dairyCowCount'),"label-for":"numberofdairy"}},[_c('b-form-input',{attrs:{"id":"numberofdairy","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.numberofdairy),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "numberofdairy", $$v)},expression:"blankVisitData.numberofdairy"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('beefCowCount'),"label-for":"numberofbeef"}},[_c('b-form-input',{attrs:{"id":"numberofbeef","type":"number","autofocus":"","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.numberofbeef),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "numberofbeef", $$v)},expression:"blankVisitData.numberofbeef"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('milkAmount'),"label-for":"milkamount"}},[_c('b-form-input',{attrs:{"id":"milkamount","type":"number","autofocus":"","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.milkamount),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "milkamount", $$v)},expression:"blankVisitData.milkamount"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('milkAvarage'),"label-for":"milkavarage"}},[_c('b-form-input',{attrs:{"id":"milkavarage","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.milkavarage),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "milkavarage", $$v)},expression:"blankVisitData.milkavarage"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('feedUsed'),"label-for":"feedused"}},[_c('b-form-input',{attrs:{"id":"feedused","autofocus":"","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.feedused),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "feedused", $$v)},expression:"blankVisitData.feedused"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"kod"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('report'),"label-for":"report"}},[_c('b-form-textarea',{attrs:{"id":"report","autofocus":"","state":_vm.formValidation(_vm.resetblankvisit).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankVisitData.report),callback:function ($$v) {_vm.$set(_vm.blankVisitData, "report", $$v)},expression:"blankVisitData.report"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }