<template>
  <div>
    <visit-list-add-new
      :is-add-new-visit-sidebar-active.sync="isAddNewVisitSidebarActive"
      @fetchvisit="fetchVisits"
    />
    <b-card>
      <b-card-title>
        <h4>{{ $t("plantVisits") }} ( {{ visits.length }} )</h4>
      </b-card-title>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="isAddNewVisitSidebarActive = !isAddNewVisitSidebarActive"
          >
            <span class="text-nowrap">{{ $t("newVisit") }}</span>
          </b-button>
        </div>
      </div>

      <b-card-body v-if="visits.length <= 0">
        {{ $t("xNotFound1", { value: $t("visit") }) }}
      </b-card-body>
      <visit-list v-else :visits.sync="visits" @fetchvisit="fetchVisits" />
    </b-card>
  </div>
</template>

<script>
import VueI18n from "@/libs/i18n";
import VisitList from "../components/VisitList.vue";
import VisitListAddNew from "./PlantVisitAdd/PlantVisitAdd.vue";
import { BCard, BCardBody, BCardTitle, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    VisitList,
    BCardTitle,
    BButton,
    VisitListAddNew,
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "plantVisits") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: VueI18n.t("plants"),
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plant"),
        to: `/plant/${to.params.id}`,
        active: false,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plantVisits"),
        active: true,
      });
    }
    next();
  },
  data: function () {
    return {
      visits: [],
      isAddNewVisitSidebarActive: false,
    };
  },
  async mounted() {
    this.fetchVisits();
  },
  methods: {
    async fetchVisits() {
      this.visits = await this.$store.dispatch(
        "plantsModule/fetchPlantVisits",
        this.$store.state.app.selectedPlantId
      );
    },
  },
};
</script>
